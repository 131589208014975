export const channels = [
  {
    uuid: "16fdfefe-e466-4090-bc1a-57c43937f826",
    type: "channel",
    title: "Guide US TV",
    country: "USA",
    provider: 7427,
    logo: "./logo.png",
    year: 2002,
  },
];
