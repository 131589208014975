import React from "react";

import { fetchChannels } from "./helpers";

import { useEpg } from "planby";

// Import theme
import { theme } from "./helpers/theme";
//import epg1 from "./admin/data.json";
export function useApp() {
  const [channels, setChannels] = React.useState([]);
  const [epg, setEpg] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const channelsData = React.useMemo(() => channels, [channels]);
  //const epgData = React.useMemo(() => epg, [epg]);
  const dateObj = new Date();
  let year = dateObj.getFullYear();
  let month = dateObj.getMonth();
  month = ("0" + (month + 1)).slice(-2);
  let date = dateObj.getDate();
  date = ("0" + date).slice(-2);
  let hour = dateObj.getHours();
  hour = ("0" + hour).slice(-2);
  let minute = dateObj.getMinutes();
  minute = ("0" + minute).slice(-2);
  let second = dateObj.getSeconds();
  second = ("0" + second).slice(-2);
  const timeS = `${year}-${month}-${date}`;
  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelsData,
    epg: epg,
    dayWidth: 17200,
    sidebarWidth: 100,
    itemHeight: 80,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    startDate: timeS,
    isBaseTimeFormat: true,
    theme,
  });
  const fetchmyJson = React.useCallback(async () => {
    await fetch("https://epg.guideus.app/fetch")
      .then((response) => response.json())
      .then((data) => {
        setEpg(data);
        setIsLoading(false);
      });
  }, []);
  const handleFetchResources = React.useCallback(async () => {
    setIsLoading(true);
    //const epg = await fetchEpg();
    const channels = await fetchChannels();
    //setEpg(epg);
    setChannels(channels);
  }, []);

  React.useEffect(() => {
    handleFetchResources();
    fetchmyJson();
  }, [handleFetchResources, fetchmyJson]);

  return { getEpgProps, getLayoutProps, isLoading };
}
